/*==============================================================================
[Table of CSS components]
==============================================================================*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Raleway:wght@700&display=swap");
h1, .h1 {
  font-size: 40px; }
  @media (max-width: 767px) {
    h1, .h1 {
      font-size: 34px; } }

h2, .h2 {
  font-size: 36px; }
  @media (max-width: 767px) {
    h2, .h2 {
      font-size: 28px; } }

h3, .h3 {
  font-size: 30px; }
  @media (max-width: 767px) {
    h3, .h3 {
      font-size: 22px; } }

h4, .h4 {
  font-size: 20px; }

h5, .h5 {
  font-size: 18px; }

h6, .h6 {
  font-size: 15px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p {
  margin: 0; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: "Raleway", sans-serif; }

h1, .h1, h2, .h2, h3 {
  font-weight: 700; }

h5, .h5, h6, .h6 {
  font-weight: 600; }

a:focus,
button:focus {
  outline: 0; }

a {
  text-decoration: none;
  display: inline-block;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s; }
  a:hover {
    color: #c8102e; }

ol {
  list-style-position: inside; }

ul, li {
  padding: 0;
  margin: 0; }

body {
  color: #7d7d7d;
  font-family: "Poppins", sans-serif;
  line-height: 1.6;
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: hidden; }

body.loaded {
  overflow-y: scroll; }

::-webkit-selection {
  color: #35382f;
  background-color: rgba(255, 170, 25, 0.7);
  text-shadow: none; }

::-moz-selection {
  color: #35382f;
  background-color: rgba(255, 170, 25, 0.7);
  text-shadow: none; }

::selection {
  color: #35382f;
  background-color: rgba(255, 170, 25, 0.7);
  text-shadow: none; }

:focus {
  outline: 0; }

/*=== UTILITIES CLASSES ===*/
.font-primary {
  font-family: "Raleway", sans-serif; }

.font-secondery {
  font-family: "Poppins", sans-serif; }

.font-w-400 {
  font-weight: 400 !important; }

.font-w-600 {
  font-weight: 600 !important; }

.font-w-700 {
  font-weight: 700 !important; }

small, .small {
  font-size: 13px !important; }

.line-h-1-5 {
  line-height: 1.5; }

.line-h-1-6 {
  line-height: 1.6; }

.line-h-1-7 {
  line-height: 1.7; }

.line-h-1-8 {
  line-height: 1.8; }

.lead {
  font-size: 18px !important;
  line-height: 30px; }
  @media (max-width: 767px) {
    .lead {
      font-size: 16px !important; } }

.bg-cover {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.section-heading {
  margin-top: -9px; }

.overlay,
.overlay-dark,
.overlay-light {
  position: relative;
  z-index: 1; }
  .overlay::after,
  .overlay-dark::after,
  .overlay-light::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-color: #000;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: .35; }

.overlay-dark::after {
  background-color: #18305d;
  opacity: .9; }

.overlay-light::after {
  background-color: #ffffff;
  opacity: .5; }

.btn {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 17px 35px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s; }
  @media (max-width: 767px) {
    .btn {
      padding: 14px 30px; } }
  .btn.btn-sm {
    font-size: 15px;
    padding: 14px 30px; }
    @media (max-width: 767px) {
      .btn.btn-sm {
        font-size: 14px;
        padding: 14px 26px; } }
  .btn:active, .btn:focus {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important; }
  .btn::after {
    position: absolute;
    content: "";
    height: 0;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: -1; }
  .btn:hover::after {
    height: 100%;
    bottom: 100%;
    -webkit-transition: height .3s, bottom .7s;
    -o-transition: height .3s, bottom .7s;
    -moz-transition: height .3s, bottom .7s;
    transition: height .3s, bottom .7s; }

.btn-primary {
  color: #35382f;
  background-color: #c8102e; }
  .btn-primary:hover, .btn-primary:active, .btn-primary:focus {
    color: #35382f;
    background-color: #c8102e; }
  .btn-primary::after {
    background-color: #ffffff; }

.btn-light {
  color: #35382f;
  background-color: #ffffff; }
  .btn-light:hover, .btn-light:active, .btn-light:focus {
    color: #35382f;
    background-color: #ffffff; }
  .btn-light::after {
    background-color: #c8102e; }

.btn-primary-outline {
  color: #35382f;
  background-color: #ffffff;
  border: 1px solid #c8102e;
  padding: 16px 35px; }
  .btn-primary-outline.btn-sm {
    padding: 13px 30px; }
    @media (max-width: 767px) {
      .btn-primary-outline.btn-sm {
        padding: 13px 26px; } }
  .btn-primary-outline:hover, .btn-primary-outline:active, .btn-primary-outline:focus {
    color: #35382f;
    background-color: #ffffff; }
  .btn-primary-outline::after {
    background-color: #c8102e; }

.owl-nav button {
  height: 30px;
  width: 30px;
  line-height: 30px !important;
  background: #babbb9 !important;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #ffffff !important;
  font-size: 12px !important;
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s;
  position: absolute;
  top: -webkit-calc(50% - 30px);
  top: -moz-calc(50% - 30px);
  top: calc(50% - 30px); }
  .owl-nav button.disabled {
    background: rgba(255, 170, 25, 0.5) !important; }
    .owl-nav button.disabled:hover {
      cursor: default;
      background: rgba(255, 170, 25, 0.5) !important; }
  .owl-nav button:hover {
    background: #333333 !important; }

.owl-nav .owl-prev {
  left: -15px !important; }

.owl-nav .owl-next {
  right: -15px !important; }

.owl-dots {
  margin-top: 25px;
  text-align: center;
  line-height: 0; }
  .owl-dots button.owl-dot {
    width: 50px;
    height: 12px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    background: #cecece !important;
    margin: 0 5px;
    margin-top: 5px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    -moz-transition: .3s;
    transition: .3s; }
    .owl-dots button.owl-dot.active {
      background-color: #c8102e !important; }

.section {
  padding-top: 100px;
  padding-bottom: 100px; }
  @media (max-width: 767px) {
    .section {
      padding-top: 70px;
      padding-bottom: 70px; } }
  .section.pt-80 {
    padding-top: 80px !important; }
    @media (max-width: 767px) {
      .section.pt-80 {
        padding-top: 50px; } }
  .section.pt-60 {
    padding-top: 60px !important; }
    @media (max-width: 767px) {
      .section.pt-60 {
        padding-top: 10px; } }

.cm-page-header {
  padding-top: 150px;
  padding-bottom: 150px; }
  @media (max-width: 767px) {
    .cm-page-header {
      padding-top: 100px;
      padding-bottom: 100px; } }
  .cm-page-header.overlay-dark::after {
    background-color: #18305d;
    opacity: .45; }

.pagination {
  margin-top: 30px; }
  .pagination li {
    margin: 0 5px; }
  .pagination a {
    display: inline-block;
    height: 35px;
    width: 35px;
    line-height: 33px;
    border: 1px solid #dbdbdb;
    color: #7d7d7d;
    text-align: center; }
    .pagination a.active, .pagination a:hover {
      text-decoration: none;
      color: #35382f;
      border-color: #c8102e;
      background-color: #c8102e; }

.check-list {
  list-style: none; }
  .check-list li {
    position: relative;
    margin-top: 15px; }
    .check-list li:first-child {
      margin-bottom: 0; }
    .check-list li::before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f00c";
      margin-right: 10px;
      font-size: 80%;
      color: #c8102e; }

.number-input {
  border: 1px solid #dbdbdb;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 48px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .number-input input[type=number]::-webkit-inner-spin-button,
  .number-input input[type=number]::-webkit-outer-spin-button {
    opacity: 1; }
  .number-input input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    max-width: 60px;
    border: 0;
    font-size: 16px;
    height: 40px;
    font-weight: bold;
    padding: 0 15px; }
  .number-input input[type=number]::-webkit-inner-spin-button,
  .number-input input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none; }
  .number-input button {
    outline: none;
    -webkit-appearance: none;
    border: none;
    cursor: pointer;
    margin: 0;
    position: relative;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: transparent;
    -webkit-transition: .3s;
    -o-transition: .3s;
    -moz-transition: .3s;
    transition: .3s;
    font-size: 12px;
    display: block;
    margin-right: 7px; }

.text-primary {
  color: #c8102e !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #c8102e !important;
  opacity: .7; }

.bg-primary {
  background-color: #c8102e !important; }

.text-dark {
  color: #35382f !important; }

a.text-dark:hover, .text-dark a:hover {
  color: #c8102e !important; }

.bg-dark {
  background-color: #35382f !important; }

.text-blue {
  color: #18305d !important; }

.bg-blue {
  background-color: #18305d !important; }

.text-gray {
  color: #cecece !important; }

.bg-gray {
  background-color: #cecece !important; }

.bg-light-gray {
  background-color: #f3f3f5 !important; }

.bg-black {
  background-color: #000000; }

.text-black {
  color: #000000; }

.text-black-100 {
  color: #111111; }

.bg-black-100 {
  background-color: #111111; }

.bg-black-200 {
  background-color: #222222; }

.text-black-200 {
  color: #222222; }

.bg-black-300 {
  background-color: #333333; }

.text-black-300 {
  color: #333333; }

.text-black-400 {
  color: #444444; }

.bg-black-400 {
  background-color: #444444; }

.bg-black-500 {
  background-color: #555555; }

.text-black-500 {
  color: #555555; }

.text-black-600 {
  color: #666666; }

.bg-black-600 {
  background-color: #666666; }

.bg-black-700 {
  background-color: #777777; }

.text-black-700 {
  color: #777777; }

.text-black-800 {
  color: #888888; }

.bg-black-800 {
  background-color: #888888; }

.bg-black-900 {
  background-color: #999999; }

.text-black-900 {
  color: #999999; }

/*===  PRELOADER CSS  ===*/
.preloader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #c8102e;
  z-index: 9999999;
  text-align: center;
  overflow: hidden;
  -webkit-transition: .5s;
  -o-transition: .5s;
  -moz-transition: .5s;
  transition: .5s; }

.preloader-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transition: .7s ease;
  -o-transition: .7s ease;
  -moz-transition: .7s ease;
  transition: .7s ease;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }
  .preloader-inner .spinner-border {
    border-width: .15em;
    position: absolute;
    top: -30%;
    color: #555555;
    left: -webkit-calc(50% - 1rem);
    left: -moz-calc(50% - 1rem);
    left: calc(50% - 1rem);
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s; }
    .preloader-inner .spinner-border span {
      display: block; }

.preloader-wrapper.loaded {
  opacity: 0;
  visibility: hidden; }

.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-animation: sk-chase 2.5s infinite linear both;
  -moz-animation: sk-chase 2.5s infinite linear both;
  -o-animation: sk-chase 2.5s infinite linear both;
  animation: sk-chase 2.5s infinite linear both; }

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation: sk-chase-dot 2.0s infinite ease-in-out both;
  -moz-animation: sk-chase-dot 2.0s infinite ease-in-out both;
  -o-animation: sk-chase-dot 2.0s infinite ease-in-out both;
  animation: sk-chase-dot 2.0s infinite ease-in-out both; }
  .sk-chase-dot::before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #35382f;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
    -moz-animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
    -o-animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both; }

.sk-chase-dot:nth-child(1) {
  -webkit-animation-delay: -1.1s;
  -moz-animation-delay: -1.1s;
  -o-animation-delay: -1.1s;
  animation-delay: -1.1s; }

.sk-chase-dot:nth-child(2) {
  -webkit-animation-delay: -1.0s;
  -moz-animation-delay: -1.0s;
  -o-animation-delay: -1.0s;
  animation-delay: -1.0s; }

.sk-chase-dot:nth-child(3) {
  -webkit-animation-delay: -0.9s;
  -moz-animation-delay: -0.9s;
  -o-animation-delay: -0.9s;
  animation-delay: -0.9s; }

.sk-chase-dot:nth-child(4) {
  -webkit-animation-delay: -0.8s;
  -moz-animation-delay: -0.8s;
  -o-animation-delay: -0.8s;
  animation-delay: -0.8s; }

.sk-chase-dot:nth-child(5) {
  -webkit-animation-delay: -0.7s;
  -moz-animation-delay: -0.7s;
  -o-animation-delay: -0.7s;
  animation-delay: -0.7s; }

.sk-chase-dot:nth-child(6) {
  -webkit-animation-delay: -0.6s;
  -moz-animation-delay: -0.6s;
  -o-animation-delay: -0.6s;
  animation-delay: -0.6s; }

.sk-chase-dot:nth-child(1):before {
  -webkit-animation-delay: -1.1s;
  -moz-animation-delay: -1.1s;
  -o-animation-delay: -1.1s;
  animation-delay: -1.1s; }

.sk-chase-dot:nth-child(2):before {
  -webkit-animation-delay: -1.0s;
  -moz-animation-delay: -1.0s;
  -o-animation-delay: -1.0s;
  animation-delay: -1.0s; }

.sk-chase-dot:nth-child(3):before {
  -webkit-animation-delay: -0.9s;
  -moz-animation-delay: -0.9s;
  -o-animation-delay: -0.9s;
  animation-delay: -0.9s; }

.sk-chase-dot:nth-child(4):before {
  -webkit-animation-delay: -0.8s;
  -moz-animation-delay: -0.8s;
  -o-animation-delay: -0.8s;
  animation-delay: -0.8s; }

.sk-chase-dot:nth-child(5):before {
  -webkit-animation-delay: -0.7s;
  -moz-animation-delay: -0.7s;
  -o-animation-delay: -0.7s;
  animation-delay: -0.7s; }

.sk-chase-dot:nth-child(6):before {
  -webkit-animation-delay: -0.6s;
  -moz-animation-delay: -0.6s;
  -o-animation-delay: -0.6s;
  animation-delay: -0.6s; }

@-webkit-keyframes sk-chase {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes sk-chase {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-o-keyframes sk-chase {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes sk-chase {
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes sk-chase-dot {
  80%, 100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes sk-chase-dot {
  80%, 100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-o-keyframes sk-chase-dot {
  80%, 100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes sk-chase-dot {
  80%, 100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes sk-chase-dot-before {
  50% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4); }
  100%, 0% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-moz-keyframes sk-chase-dot-before {
  50% {
    -moz-transform: scale(0.4);
    transform: scale(0.4); }
  100%, 0% {
    -moz-transform: scale(1);
    transform: scale(1); } }

@-o-keyframes sk-chase-dot-before {
  50% {
    -o-transform: scale(0.4);
    transform: scale(0.4); }
  100%, 0% {
    -o-transform: scale(1);
    transform: scale(1); } }

@keyframes sk-chase-dot-before {
  50% {
    -webkit-transform: scale(0.4);
    -moz-transform: scale(0.4);
    -o-transform: scale(0.4);
    transform: scale(0.4); }
  100%, 0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@media (min-width: 991px) {
  .navbar .dropdown-menu {
    display: block;
    opacity: 0;
    visibility: hidden;
    width: 220px;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    margin-top: 15px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0; }
  .navbar .dropdown:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
    -webkit-box-shadow: 0 10px 30px rgba(22, 28, 45, 0.1);
    -moz-box-shadow: 0 10px 30px rgba(22, 28, 45, 0.1);
    box-shadow: 0 10px 30px rgba(22, 28, 45, 0.1); } }

.dropdown-menu {
  border: 0;
  padding: 10px 0; }

.dropdown-item {
  font-size: 16px;
  padding: 8px 20px; }

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:hover {
  color: #ffffff;
  background-color: #c8102e; }

.navbar-toggler {
  border: 0; }
  .navbar-toggler span {
    color: #35382f; }

.dropdown-toggle::after {
  display: none; }

@media (max-width: 991px) {
  .dropdown-menu {
    border: 0;
    padding: 0;
    text-align: left !important;
    background: #f2faff;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px; }
  .navbar .navbar-nav {
    margin-top: 15px;
    width: 250px;
    max-width: 100%;
    margin-right: auto;
    -webkit-box-align: initial !important;
    -webkit-align-items: initial !important;
    -moz-box-align: initial !important;
    -ms-flex-align: initial !important;
    align-items: initial !important; }
  .navbar .nav-item {
    display: block;
    text-align: left; }
  .navbar .nav-link {
    padding: 8px 4px !important; }
  .navbar .dropdown-menu li:first-child {
    padding-top: 10px; }
  .navbar .dropdown-menu li:last-child {
    padding-bottom: 10px; } }

.cm-header .nav-link {
  text-transform: uppercase;
  font-weight: 600;
  padding: 10px 15px !important; }

.cm-header .navbar-light .nav-link {
  color: #35382f !important; }
  .cm-header .navbar-light .nav-link:hover {
    opacity: .7; }
  @media (max-width: 991px) {
    .cm-header .navbar-light .nav-link {
      text-align: center; } }

.cm-header .nav-item.active .nav-link {
  color: #c8102e !important; }

@media (max-width: 991px) {
  .cm-header .header-top {
    padding: 7px 0; } }

.cm-header .block .icon {
  display: inline-block;
  border: 1px solid #46597d;
  border-top: 0;
  border-bottom: 0;
  padding: 15px 16px; }
  @media (max-width: 991px) {
    .cm-header .block .icon {
      padding: 0;
      border: 0 !important;
      margin-right: 5px; }
      .cm-header .block .icon:first-child {
        padding-left: 0 !important; } }

@media (max-width: 991px) {
  .cm-header .block {
    font-size: 15px;
    text-align: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-left: 0 !important; }
    .cm-header .block.mr-35 {
      margin-right: 25px; } }

@media (max-width: 767px) {
  .cm-header .block {
    font-size: 13px; }
    .cm-header .block.mr-35 {
      margin-right: 0; } }

.cm-header .header {
  padding: 12px 0;
  -webkit-box-shadow: 0 0 30px -15px #cecece;
  -moz-box-shadow: 0 0 30px -15px #cecece;
  box-shadow: 0 0 30px -15px #cecece; }
  @media (max-width: 991px) {
    .cm-header .header {
      padding: 8px 0; }
      .cm-header .header .navbar-brand img {
        width: 160px; }
      .cm-header .header .dropdown-item {
        text-align: center; } }
  @media (max-width: 767px) {
    .cm-header .header {
      padding: 4px 0; } }
  .cm-header .header .block {
    margin-left: 15px; }
    .cm-header .header .block .icon {
      padding: 5px 20px;
      position: relative;
      border: 0;
      -webkit-transition: .3s;
      -o-transition: .3s;
      -moz-transition: .3s;
      transition: .3s; }
      .cm-header .header .block .icon:hover {
        color: #c8102e !important; }
      .cm-header .header .block .icon::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 1px;
        background-color: rgba(206, 206, 206, 0.5);
        left: 0;
        top: 0; }
      .cm-header .header .block .icon:first-child::after {
        display: none; }

.cm-header.cm-header-2 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 15; }
  .cm-header.cm-header-2 .header {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }
    .cm-header.cm-header-2 .header .navbar-light .nav-link {
      color: #ffffff !important; }
    @media (max-width: 991px) {
      .cm-header.cm-header-2 .header .navbar-light .nav-link {
        color: #35382f !important; }
      .cm-header.cm-header-2 .header .navbar-collapse {
        background: #fff; } }
    .cm-header.cm-header-2 .header .nav-item.active .nav-link {
      color: #c8102e !important; }
    .cm-header.cm-header-2 .header .navbar-toggler {
      background-color: #fff;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      padding: 7px !important; }
      .cm-header.cm-header-2 .header .navbar-toggler span {
        font-size: 15px; }
    .cm-header.cm-header-2 .header .block {
      margin-left: 0; }
      @media (max-width: 991px) {
        .cm-header.cm-header-2 .header .block {
          -webkit-box-pack: center;
          -webkit-justify-content: center;
          -moz-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          margin-bottom: 20px; } }
      .cm-header.cm-header-2 .header .block .icon {
        padding: 5px;
        margin-right: 25px;
        color: #ffffff !important; }
        @media (max-width: 991px) {
          .cm-header.cm-header-2 .header .block .icon {
            color: #35382f !important; } }
        .cm-header.cm-header-2 .header .block .icon:hover {
          color: #c8102e !important; }
        .cm-header.cm-header-2 .header .block .icon::after {
          display: none; }

.search-wrapper {
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  z-index: 9995;
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
  background: rgba(0, 0, 0, 0.8); }
  .search-wrapper .form-control {
    height: 60px;
    width: 350px;
    max-width: 100%;
    padding-left: 30px; }
    .search-wrapper .form-control:focus {
      border-color: #555555; }
    @media (max-width: 480px) {
      .search-wrapper .form-control {
        width: 250px; } }
  .search-wrapper .form-group {
    background: #fff;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: .3s;
    -o-transition: .3s;
    -moz-transition: .3s;
    transition: .3s;
    opacity: 0; }
  .search-wrapper button {
    height: 60px;
    width: 70px; }
    @media (max-width: 480px) {
      .search-wrapper button {
        width: 55px; } }
  .search-wrapper .search-close-btn {
    height: 45px;
    width: 45px;
    line-height: 45px;
    margin: 0 auto;
    margin-top: 50px;
    background-color: #c8102e;
    color: #ffffff;
    position: absolute;
    bottom: 0;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    left: 50%;
    -webkit-transition: .3s;
    -o-transition: .3s;
    -moz-transition: .3s;
    transition: .3s;
    opacity: 0; }
    .search-wrapper .search-close-btn:hover {
      opacity: .8; }
  .search-wrapper .search-close-wrap {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    cursor: -webkit-zoom-out;
    cursor: -moz-zoom-out;
    cursor: zoom-out; }

.showSearch {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }
  .showSearch .search-close-btn {
    bottom: 70px;
    opacity: 1;
    -webkit-transition-delay: .2s;
    -moz-transition-delay: .2s;
    -o-transition-delay: .2s;
    transition-delay: .2s; }
  .showSearch .form-group {
    top: 70px;
    opacity: 1;
    -webkit-transition-delay: .2s;
    -moz-transition-delay: .2s;
    -o-transition-delay: .2s;
    transition-delay: .2s; }

.cm-banner {
  padding-top: 200px;
  padding-bottom: 250px; }
  @media (max-width: 1369px) {
    .cm-banner {
      padding-top: 150px;
      padding-bottom: 200px; } }
  .cm-banner .block h3 {
    margin-top: -4px; }
  @media (max-width: 767px) {
    .cm-banner .block .display-3 {
      font-size: 56px; } }
  @media (max-width: 575px) {
    .cm-banner .block .display-3 {
      font-size: 42px; } }
  @media (max-width: 575px) {
    .cm-banner .block .lead {
      margin-top: 15px;
      margin-bottom: 25px; } }

.banner-carousel .overlay::after {
  background-color: #000;
  opacity: .6; }

.banner-carousel .cm-banner {
  padding-top: 300px; }
  @media (max-width: 1369px) {
    .banner-carousel .cm-banner {
      padding-top: 250px; } }
  @media (max-width: 991px) {
    .banner-carousel .cm-banner {
      padding-top: 210px;
      padding-bottom: 150px; } }
  @media (max-width: 575px) {
    .banner-carousel .cm-banner {
      padding-top: 180px;
      padding-bottom: 150px; } }

.banner-carousel .owl-nav button {
  height: 50px;
  width: 50px;
  line-height: 50px !important; }

.banner-carousel .owl-nav .owl-prev {
  left: 25px !important; }

.banner-carousel .owl-nav .owl-next {
  right: 25px !important; }

@media (max-width: 991px) {
  .banner-carousel .owl-nav button {
    height: 40px;
    width: 40px;
    line-height: 40px !important;
    top: -webkit-calc(100% - 65px);
    top: -moz-calc(100% - 65px);
    top: calc(100% - 65px); } }

.cm-banner-03 {
  padding: 150px 0; }
  @media (max-width: 767px) {
    .cm-banner-03 {
      padding: 100px 0; } }
  @media (max-width: 767px) {
    .cm-banner-03 .display-4 {
      font-size: 56px; } }
  @media (max-width: 575px) {
    .cm-banner-03 .display-4 {
      font-size: 38px; } }
  .cm-banner-03.overlay-dark::after {
    opacity: .5; }

.banner-form {
  border: 1px solid #c8102e; }
  .banner-form .form-control {
    height: 45px; }
    .banner-form .form-control:focus {
      border-color: #888888; }
  .banner-form textarea.form-control {
    height: 90px; }
  .banner-form .nice-select {
    height: 45px;
    line-height: 43px;
    background-color: #f3f3f5; }
    .banner-form .nice-select::after {
      height: 6px;
      right: 18px;
      width: 6px; }
    .banner-form .nice-select .list {
      width: 100%; }

.cm-features {
  margin-top: -80px;
  position: relative;
  z-index: 3; }
  .cm-features .block {
    margin-top: 30px;
    padding: 30px 35px;
    -webkit-box-shadow: 0 5px 25px -5px #eee;
    -moz-box-shadow: 0 5px 25px -5px #eee;
    box-shadow: 0 5px 25px -5px #eee;
    background-color: #ffffff; }
    @media (max-width: 575px) {
      .cm-features .block {
        padding: 30px 25px; }
        .cm-features .block h4 {
          font-size: 18px; } }
    .cm-features .block .icon {
      height: 50px;
      width: 50px;
      line-height: 50px;
      text-align: center;
      margin-right: 20px;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      -moz-transition: 0.3s;
      transition: 0.3s; }
      .cm-features .block .icon img {
        width: 22px; }
    .cm-features .block:hover .icon {
      -webkit-border-radius: 17px;
      -moz-border-radius: 17px;
      border-radius: 17px; }
      .cm-features .block:hover .icon img {
        -webkit-transition: .7s;
        -o-transition: .7s;
        -moz-transition: .7s;
        transition: .7s;
        -webkit-transform: rotateY(360deg);
        -moz-transform: rotateY(360deg);
        transform: rotateY(360deg); }

.cm-how-can-help .block {
  padding: 30px 25px;
  background-color: #ffffff;
  border: 1px solid #c8102e;
  position: relative;
  z-index: 1; }
  .cm-how-can-help .block .icon {
    height: 70px;
    width: 70px;
    text-align: center;
    line-height: 70px;
    margin-bottom: 25px;
    background-color: #c8102e;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    -moz-transition: 0.3s;
    transition: 0.3s; }
    .cm-how-can-help .block .icon img {
      height: 30px;
      width: 30px; }
  .cm-how-can-help .block:hover .icon {
    -webkit-border-radius: 17px;
    -moz-border-radius: 17px;
    border-radius: 17px; }
    .cm-how-can-help .block:hover .icon img {
      -webkit-transition: .7s;
      -o-transition: .7s;
      -moz-transition: .7s;
      transition: .7s;
      -webkit-transform: rotateY(-360deg);
      -moz-transform: rotateY(-360deg);
      transform: rotateY(-360deg); }
  .cm-how-can-help .block::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    -webkit-transition: .2s;
    -o-transition: .2s;
    -moz-transition: .2s;
    transition: .2s;
    top: 0;
    left: 0;
    z-index: -1; }
  .cm-how-can-help .block.active, .cm-how-can-help .block:hover {
    color: #ffffff;
    border-color: transparent; }
    .cm-how-can-help .block.active::after, .cm-how-can-help .block:hover::after {
      opacity: .7;
      background-color: #18305d; }
    .cm-how-can-help .block.active .text-dark, .cm-how-can-help .block:hover .text-dark {
      color: #ffffff !important; }

.cm-how-can-help.cm-how-can-help-2 .block {
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s; }
  .cm-how-can-help.cm-how-can-help-2 .block .context img {
    -webkit-transition: .5s;
    -o-transition: .5s;
    -moz-transition: .5s;
    transition: .5s; }
  .cm-how-can-help.cm-how-can-help-2 .block::after {
    display: none; }
  .cm-how-can-help.cm-how-can-help-2 .block:hover {
    color: #7d7d7d;
    border-color: transparent;
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
    transform: translateY(-2px);
    -webkit-box-shadow: 5px 0 20px -5px #eee;
    -moz-box-shadow: 5px 0 20px -5px #eee;
    box-shadow: 5px 0 20px -5px #eee; }
    .cm-how-can-help.cm-how-can-help-2 .block:hover .text-dark {
      color: #35382f !important; }
    .cm-how-can-help.cm-how-can-help-2 .block:hover .context img {
      -webkit-transform: scale(0.9) rotateY(360deg);
      -moz-transform: scale(0.9) rotateY(360deg);
      transform: scale(0.9) rotateY(360deg); }

.cm-services .nav-link {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px 35px;
  color: #cecece !important;
  text-transform: uppercase;
  font-weight: 600; }
  .cm-services .nav-link:last-child {
    border-bottom: 0; }
  .cm-services .nav-link:hover, .cm-services .nav-link.active {
    background-color: #c8102e;
    color: #35382f !important; }

.cm-services.cm-services-02 .nav-link {
  border-bottom: 1px solid rgba(53, 56, 47, 0.1);
  background-color: rgba(53, 56, 47, 0.05);
  padding: 20px 35px;
  color: #35382f !important;
  text-transform: uppercase;
  font-weight: 600; }
  .cm-services.cm-services-02 .nav-link:last-child {
    border-bottom: 0; }
  .cm-services.cm-services-02 .nav-link:hover, .cm-services.cm-services-02 .nav-link.active {
    background-color: #c8102e;
    color: #35382f !important; }

.pricing-item h4 {
  padding: 25px 10px;
  background-color: gray; }

.pricing-item .list-unstyled li {
  padding: 12px 15px; }
  .pricing-item .list-unstyled li:nth-child(odd) {
    background-color: #f3f3f5; }

.pricing-item .pricing-footer {
  background-color: #f3f3f5;
  padding: 30px 0; }

.why-choose-us .block {
  padding: 40px 30px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s;
  -webkit-box-shadow: 1px 0 10px rgba(0, 0, 0, 0.02);
  -moz-box-shadow: 1px 0 10px rgba(0, 0, 0, 0.02);
  box-shadow: 1px 0 10px rgba(0, 0, 0, 0.02); }
  .why-choose-us .block:hover {
    -webkit-box-shadow: 3px 0 20px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 3px 0 20px rgba(0, 0, 0, 0.07);
    box-shadow: 3px 0 20px rgba(0, 0, 0, 0.07);
    -webkit-transform: translateY(-3px);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
    transform: translateY(-3px); }
  .why-choose-us .block .icon {
    height: 60px;
    width: 60px;
    line-height: 60px;
    text-align: center;
    background-color: #c8102e;
    margin-left: auto;
    margin-right: auto;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    -moz-transition: 0.3s;
    transition: 0.3s; }
    .why-choose-us .block .icon img {
      height: 30px;
      width: 30px; }

.why-choose-us .bg-shape-image {
  padding: 70px 50px;
  background-image: url(../images/why-choose-us/bg.png);
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom; }
  @media (max-width: 575px) {
    .why-choose-us .bg-shape-image {
      padding: 60px 30px; } }
  @media (max-width: 480px) {
    .why-choose-us .bg-shape-image {
      padding: 60px 15px; } }
  .why-choose-us .bg-shape-image .icon img {
    height: auto;
    margin: auto;
    padding-top: 15px; }
  .why-choose-us .bg-shape-image .block:hover {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
    .why-choose-us .bg-shape-image .block:hover .icon {
      -webkit-border-radius: 17px;
      -moz-border-radius: 17px;
      border-radius: 17px; }
  .why-choose-us .bg-shape-image .owl-dots {
    text-align: left;
    margin-left: -3px; }
    .why-choose-us .bg-shape-image .owl-dots button.owl-dot {
      width: 12px;
      height: 12px; }
      .why-choose-us .bg-shape-image .owl-dots button.owl-dot.active {
        width: 20px; }

.cm-first-time .block {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s; }
  .cm-first-time .block.active, .cm-first-time .block:hover {
    background-color: #fff !important;
    -webkit-box-shadow: 0 0 15px #ddd;
    -moz-box-shadow: 0 0 15px #ddd;
    box-shadow: 0 0 15px #ddd;
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
    transform: translateY(-2px); }

.product-filter-buttons button,
.product-filter-buttons-2 button {
  border: 1px solid #bbbbbb;
  color: #35382f; }
  .product-filter-buttons button:hover, .product-filter-buttons button.active,
  .product-filter-buttons-2 button:hover,
  .product-filter-buttons-2 button.active {
    background-color: #c8102e;
    border-color: #c8102e; }
  @media (max-width: 991px) {
    .product-filter-buttons button,
    .product-filter-buttons-2 button {
      padding: 15px 25px;
      font-size: 15px; } }
  @media (max-width: 767px) {
    .product-filter-buttons button,
    .product-filter-buttons-2 button {
      border: 0; } }

@media (max-width: 767px) {
  .product-filter-buttons,
  .product-filter-buttons-2 {
    display: block; } }

.carousel-fluid {
  width: 1310px;
  margin: auto;
  max-width: 100%;
  padding: 0 20px; }

.fluid-carousel .product-item .card-img-top {
  border-color: rgba(255, 170, 25, 0.2) !important; }

.product-img-style {
  border: 1px solid #dbdbdb;
  position: relative; }
  .product-img-style::after, .product-img-style::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 70%;
    background-color: #ffffff;
    left: 15%; }
  .product-img-style::after {
    bottom: -1px; }
  .product-img-style::before {
    top: -1px; }

.border-bottom-tab .nav-tabs {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 10px; }
  .border-bottom-tab .nav-tabs .nav-item {
    margin-bottom: 0; }
  .border-bottom-tab .nav-tabs .nav-link {
    border: 0;
    font-size: 18px;
    font-weight: bold; }
    .border-bottom-tab .nav-tabs .nav-link.active {
      color: #35382f !important; }

.product-item {
  overflow: hidden;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }
  .product-item .card-img-top {
    -webkit-transition: .3s;
    -o-transition: .3s;
    -moz-transition: .3s;
    transition: .3s; }
  .product-item .show-on-hover {
    text-align: center;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    -webkit-transition: .3s;
    -o-transition: .3s;
    -moz-transition: .3s;
    transition: .3s; }
    .product-item .show-on-hover .btn {
      font-size: 12px;
      padding: 13px 22px; }
  .product-item .card-body {
    position: relative; }
  .product-item:hover .card-img-top {
    -webkit-filter: saturate(0) blur(1px);
    filter: saturate(0) blur(1px);
    -webkit-transform: scale(0.99);
    -moz-transform: scale(0.99);
    -ms-transform: scale(0.99);
    -o-transform: scale(0.99);
    transform: scale(0.99); }
  .product-item:hover .show-on-hover {
    top: -60px;
    opacity: 1; }

.cm-cta.overlay-light::after {
  display: none; }
  @media (max-width: 767px) {
    .cm-cta.overlay-light::after {
      display: block; } }

.cm-cta-02 {
  overflow: hidden;
  position: relative; }
  @media (max-width: 991px) {
    .cm-cta-02 .cta-img {
      display: none; } }

@media (max-width: 575px) {
  .cm-team {
    padding-bottom: 110px; } }

@media (max-width: 575px) {
  .cm-team .team-img {
    width: 250px !important; } }

.cm-team .owl-nav .owl-prev {
  left: auto !important;
  right: 45px !important;
  top: -80px;
  -webkit-transform: translate(0);
  -moz-transform: translate(0);
  -ms-transform: translate(0);
  -o-transform: translate(0);
  transform: translate(0); }
  @media (max-width: 575px) {
    .cm-team .owl-nav .owl-prev {
      top: auto;
      bottom: -80px;
      right: -webkit-calc(50% + 10px) !important;
      right: -moz-calc(50% + 10px) !important;
      right: calc(50% + 10px) !important; } }

.cm-team .owl-nav .owl-next {
  right: 0px !important;
  top: -80px;
  -webkit-transform: translate(0);
  -moz-transform: translate(0);
  -ms-transform: translate(0);
  -o-transform: translate(0);
  transform: translate(0); }
  @media (max-width: 575px) {
    .cm-team .owl-nav .owl-next {
      top: auto;
      bottom: -80px;
      right: -webkit-calc(50% - 30px) !important;
      right: -moz-calc(50% - 30px) !important;
      right: calc(50% - 30px) !important; } }

.cm-team .barWrapper {
  margin-top: 25px; }
  .cm-team .barWrapper .progress-text {
    font-weight: 600;
    color: #35382f;
    display: inline-block;
    margin-bottom: 5px; }

.cm-team .progress {
  overflow: visible;
  height: 7px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  position: relative; }
  .cm-team .progress .progress-bar {
    background: #c8102e;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    overflow: visible;
    position: relative;
    opacity: 0;
    -webkit-transition: opacity .3s, width 1.5s ease-in-out;
    -o-transition: opacity .3s, width 1.5s ease-in-out;
    -moz-transition: opacity .3s, width 1.5s ease-in-out;
    transition: opacity .3s, width 1.5s ease-in-out; }
    .cm-team .progress .progress-bar .skill-percent {
      position: absolute;
      color: #ffffff;
      right: 0;
      height: 26px;
      width: 30px;
      line-height: 26px;
      background-color: #35382f;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      bottom: 14px; }
      .cm-team .progress .progress-bar .skill-percent::after {
        position: absolute;
        content: "";
        border-left: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 5px solid #35382f;
        border-top: 5px solid #35382f;
        bottom: -7px;
        right: 0; }

.testimonial-item {
  padding: 30px;
  background-color: #ffffff;
  border-left: 5px solid #c8102e;
  -webkit-box-shadow: 0 10px 25px #eee;
  -moz-box-shadow: 0 10px 25px #eee;
  box-shadow: 0 10px 25px #eee; }

.blog-item {
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }
  .blog-item .card-img-top {
    -webkit-transition: .3s;
    -o-transition: .3s;
    -moz-transition: .3s;
    transition: .3s; }
  .blog-item:hover .card-img-top {
    -webkit-filter: sepia(0.4);
    filter: sepia(0.4); }
  .blog-item .blog-meta-overlap {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 20px;
    font-weight: 900;
    color: #ffffff;
    background-color: #c8102e;
    height: 50px;
    width: 50px;
    text-align: center;
    padding-top: 8px;
    line-height: 1; }
    .blog-item .blog-meta-overlap span {
      display: block;
      font-weight: 500;
      font-size: 13px;
      text-transform: uppercase; }

.quote-info {
  position: relative; }
  .quote-info::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-image: url(../images/quote-bg.png);
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: 0;
    left: 0;
    opacity: .3; }

.quote-text {
  line-height: 1.9; }

.cm-brands {
  padding-top: 60px; }
  @media (max-width: 767px) {
    .cm-brands {
      padding-top: 30px; } }

.divider {
  border-top: 1px solid #ddd; }

.work-gallery .work-item {
  position: relative;
  padding-bottom: 24px;
  overflow: hidden;
  position: relative; }
  .work-gallery .work-item::after {
    position: absolute;
    content: "";
    height: 0;
    width: 100%;
    background-color: #18305d;
    top: 0;
    left: 0; }
  .work-gallery .work-item .img-fluid {
    -webkit-transition: .5s;
    -o-transition: .5s;
    -moz-transition: .5s;
    transition: .5s; }
  .work-gallery .work-item .btn {
    position: absolute;
    bottom: 0;
    right: 15px;
    width: 70%; }
  .work-gallery .work-item:hover .btn {
    right: 50%;
    -webkit-transform: translateX(50%);
    -moz-transform: translateX(50%);
    -ms-transform: translateX(50%);
    -o-transform: translateX(50%);
    transform: translateX(50%);
    background-color: #c8102e; }
  .work-gallery .work-item:hover .img-fluid {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    -ms-transform: scale(1.15);
    -o-transform: scale(1.15);
    transform: scale(1.15); }
  .work-gallery .work-item:hover::after {
    height: 100%;
    opacity: .2;
    -webkit-transition: .5s;
    -o-transition: .5s;
    -moz-transition: .5s;
    transition: .5s; }

.work-gallery .owl-nav button {
  top: -webkit-calc(50% - 44px);
  top: -moz-calc(50% - 44px);
  top: calc(50% - 44px); }

.widget {
  margin-top: 30px; }
  .widget .widget-heading {
    padding: 15px 10px;
    color: #35382f;
    background-color: #c8102e;
    text-transform: capitalize;
    text-align: center;
    font-size: 20px;
    font-weight: bold; }
  .widget.widget-search form {
    background: #f2f2f3;
    border: 1px solid #dbdbdb;
    padding: 7px;
    padding-right: 10px; }
  .widget.widget-search button {
    color: #7d7d7d; }

.footer-links a {
  position: relative;
  color: #cecece;
  margin-top: 20px; }
  .footer-links a:hover {
    text-decoration: none;
    color: #c8102e; }
  .footer-links a::before {
    font-family: 'Font Awesome 5 Free';
    content: "\f054";
    font-weight: 900;
    margin-right: 8px;
    font-size: 14px; }

.footer-bottom {
  padding: 20px 10px;
  background-color: #18305d;
  color: #ffffff; }

.social-links li {
  display: inline-block;
  margin-right: 10px; }
  .social-links li:last-child {
    margin-right: 0; }
  .social-links li a {
    display: inline-block;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px solid #728199;
    color: #c8102e;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px; }
    .social-links li a:hover {
      background-color: #c8102e;
      color: #ffffff;
      border-color: transparent; }

.input-group-primary {
  height: 45px;
  border: 1px solid #7988a1;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  max-width: 250px; }
  .input-group-primary .form-control {
    height: 44px; }
  .input-group-primary .form-control::-webkit-input-placeholder {
    color: #ffffff;
    opacity: .7; }
  .input-group-primary .form-control:-moz-placeholder {
    color: #ffffff;
    opacity: .7; }
  .input-group-primary .form-control::-moz-placeholder {
    color: #ffffff;
    opacity: .7; }
  .input-group-primary .form-control:-ms-input-placeholder {
    color: #ffffff;
    opacity: .7; }
  .input-group-primary .form-control::-ms-input-placeholder {
    color: #ffffff;
    opacity: .7; }
  .input-group-primary .form-control::placeholder {
    color: #ffffff;
    opacity: .7; }
  .input-group-primary .btn {
    color: #ffffff !important;
    padding: 0;
    line-height: 39px;
    height: 39px;
    width: 39px;
    font-size: 13px;
    margin-top: 2px;
    margin-right: 2px; }

a.scroll-to-top {
  position: fixed;
  bottom: 0;
  right: 30px;
  height: 40px;
  width: 40px;
  background: #c8102e;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s;
  z-index: 995;
  font-size: 14px;
  opacity: 0;
  visibility: hidden; }
  @media (max-width: 767px) {
    a.scroll-to-top {
      right: 10px;
      height: 30px;
      width: 30px;
      line-height: 30px;
      font-size: 12px; } }
  a.scroll-to-top.show {
    bottom: 40px;
    opacity: 1;
    visibility: visible; }
    a.scroll-to-top.show:hover {
      -webkit-transform: translateY(-4px);
      -moz-transform: translateY(-4px);
      -ms-transform: translateY(-4px);
      -o-transform: translateY(-4px);
      transform: translateY(-4px); }
    @media (max-width: 767px) {
      a.scroll-to-top.show {
        bottom: 10px; } }
